<template>
    <div class="d-flex justify-content-center m-3">
        <div>
            <div class="text-center">
                <h1>HEADER</h1>
            </div>
            <div class="content_block p-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, aperiam blanditiis. Quasi iste atque reiciendis a unde laudantium ipsam
                in vitae, recusandae itaque modi est minima porro consequatur hic. Expedita. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Saepe ducimus eum dolores sit iure. Corrupti minima eius facere, culpa voluptatum vero aspernatur iure ad, quam facilis quae, ut fuga
                animi. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo ad, expedita nam vero quos quibusdam labore dolor iure ipsa cumque
                molestiae aliquam nihil eligendi commodi, ea delectus obcaecati nesciunt sequi! Lorem ipsum dolor sit amet consectetur adipisicing
                elit. Magnam alias sint quod quisquam nulla dolore, eligendi autem aperiam error nam, debitis dignissimos consequuntur mollitia. Velit
                vel earum ipsa. Incidunt, commodi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat distinctio labore aspernatur,
                corrupti esse repellendus maiores illum aliquid accusamus aperiam cupiditate ratione iure, quidem rem ab temporibus delectus odit
                praesentium. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt doloribus quam obcaecati accusamus maxime voluptates a
                odio, aliquam est, dolores consequatur fugiat debitis aspernatur ex. Voluptas cumque provident sint voluptatum? Lorem ipsum dolor sit
                amet consectetur adipisicing elit. Odit, aperiam blanditiis. Quasi iste atque reiciendis a unde laudantium ipsam in vitae, recusandae
                itaque modi est minima porro consequatur hic. Expedita. Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ducimus eum
                dolores sit iure. Corrupti minima eius facere, culpa voluptatum vero aspernatur iure ad, quam facilis quae, ut fuga animi. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Quo ad, expedita nam vero quos quibusdam labore dolor iure ipsa cumque molestiae aliquam
                nihil eligendi commodi, ea delectus obcaecati nesciunt sequi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam alias
                sint quod quisquam nulla dolore, eligendi autem aperiam error nam, debitis dignissimos consequuntur mollitia. Velit vel earum ipsa.
                Incidunt, commodi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat distinctio labore aspernatur, corrupti esse
                repellendus maiores illum aliquid accusamus aperiam cupiditate ratione iure, quidem rem ab temporibus delectus odit praesentium. Lorem
                ipsum dolor sit amet consectetur adipisicing elit. Sunt doloribus quam obcaecati accusamus maxime voluptates a odio, aliquam est,
                dolores consequatur fugiat debitis aspernatur ex. Voluptas cumque provident sint voluptatum? Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Odit, aperiam blanditiis. Quasi iste atque reiciendis a unde laudantium ipsam in vitae, recusandae itaque modi est
                minima porro consequatur hic. Expedita. Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ducimus eum dolores sit iure.
                Corrupti minima eius facere, culpa voluptatum vero aspernatur iure ad, quam facilis quae, ut fuga animi. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Quo ad, expedita nam vero quos quibusdam labore dolor iure ipsa cumque molestiae aliquam nihil eligendi
                commodi, ea delectus obcaecati nesciunt sequi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam alias sint quod
                quisquam nulla dolore, eligendi autem aperiam error nam, debitis dignissimos consequuntur mollitia. Velit vel earum ipsa. Incidunt,
                commodi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat distinctio labore aspernatur, corrupti esse repellendus
                maiores illum aliquid accusamus aperiam cupiditate ratione iure, quidem rem ab temporibus delectus odit praesentium. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Sunt doloribus quam obcaecati accusamus maxime voluptates a odio, aliquam est, dolores
                consequatur fugiat debitis aspernatur ex. Voluptas cumque provident sint voluptatum? Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Odit, aperiam blanditiis. Quasi iste atque reiciendis a unde laudantium ipsam in vitae, recusandae itaque modi est
                minima porro consequatur hic. Expedita. Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ducimus eum dolores sit iure.
                Corrupti minima eius facere, culpa voluptatum vero aspernatur iure ad, quam facilis quae, ut fuga animi. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Quo ad, expedita nam vero quos quibusdam labore dolor iure ipsa cumque molestiae aliquam nihil eligendi
                commodi, ea delectus obcaecati nesciunt sequi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam alias sint quod
                quisquam nulla dolore, eligendi autem aperiam error nam, debitis dignissimos consequuntur mollitia. Velit vel earum ipsa. Incidunt,
                commodi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat distinctio labore aspernatur, corrupti esse repellendus
                maiores illum aliquid accusamus aperiam cupiditate ratione iure, quidem rem ab temporibus delectus odit praesentium. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Sunt doloribus quam obcaecati accusamus maxime voluptates a odio, aliquam est, dolores
                consequatur fugiat debitis aspernatur ex. Voluptas cumque provident sint voluptatum? Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Odit, aperiam blanditiis. Quasi iste atque reiciendis a unde laudantium ipsam in vitae, recusandae itaque modi est
                minima porro consequatur hic. Expedita. Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ducimus eum dolores sit iure.
                Corrupti minima eius facere, culpa voluptatum vero aspernatur iure ad, quam facilis quae, ut fuga animi. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Quo ad, expedita nam vero quos quibusdam labore dolor iure ipsa cumque molestiae aliquam nihil eligendi
                commodi, ea delectus obcaecati nesciunt sequi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam alias sint quod
                quisquam nulla dolore, eligendi autem aperiam error nam, debitis dignissimos consequuntur mollitia. Velit vel earum ipsa. Incidunt,
                commodi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat distinctio labore aspernatur, corrupti esse repellendus
                maiores illum aliquid accusamus aperiam cupiditate ratione iure, quidem rem ab temporibus delectus odit praesentium. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Sunt doloribus quam obcaecati accusamus maxime voluptates a odio, aliquam est, dolores
                consequatur fugiat debitis aspernatur ex. Voluptas cumque provident sint voluptatum? Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Odit, aperiam blanditiis. Quasi iste atque reiciendis a unde laudantium ipsam in vitae, recusandae itaque modi est
                minima porro consequatur hic. Expedita. Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe ducimus eum dolores sit iure.
                Corrupti minima eius facere, culpa voluptatum vero aspernatur iure ad, quam facilis quae, ut fuga animi. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Quo ad, expedita nam vero quos quibusdam labore dolor iure ipsa cumque molestiae aliquam nihil eligendi
                commodi, ea delectus obcaecati nesciunt sequi! Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam alias sint quod
                quisquam nulla dolore, eligendi autem aperiam error nam, debitis dignissimos consequuntur mollitia. Velit vel earum ipsa. Incidunt,
                commodi. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat distinctio labore aspernatur, corrupti esse repellendus
                maiores illum aliquid accusamus aperiam cupiditate ratione iure, quidem rem ab temporibus delectus odit praesentium. Lorem ipsum dolor
                sit amet consectetur adipisicing elit. Sunt doloribus quam obcaecati accusamus maxime voluptates a odio, aliquam est, dolores
                consequatur fugiat debitis aspernatur ex. Voluptas cumque provident sint voluptatum?
            </div>
            <div class="text-center mt-2">
                <h1>FOOTER</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IfamePage'
};
</script>

<style lang="scss" scoped></style>
